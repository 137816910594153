import React from "react"

export const TECHNOLOGIES = [
  { name: "React", icon: "react-native.svg" },
  { name: "NodeJS", icon: "nodejs.svg" },
  { name: "NestJS", icon: "nestjs.svg" },
  { name: "HTML5", icon: "html5-txt.svg" },
  { name: "CSS", icon: "css3-txt.svg" },
  { name: "Storybook", icon: "storybook.svg" },
  { name: "Rest API", icon: "restapi.png" },
  { name: "Figma", icon: "icon-figma.svg" },
  { name: "Sendgrid", icon: "sendgrid.svg" },
  { name: "Postgres", icon: "postgresql.svg" },
]

export const DELIVERABLES = [
  {
    title: "Application for business",
    text: "Protects SMBs with risk assessments, monitoring, & training",
    icon: "business",
  },
  {
    title: "Application for Agents",
    text: "Tool for brokers to manage portfolios, assess cyber risks",
    icon: "agents",
  },
  {
    title: "Admin Panel",
    text: "Centralized panel to manage business and agent apps",
    icon: "admin-panel",
  },
  {
    title: "Application for individuals",
    text: "Personalized app to protect data, transactions, and assets",
    icon: "individuals",
  },
]

export const CHALLENGES = [
  {
    id: 1,
    title: "Complex Data Integration",
    text:
      "Integrating multiple trusted APIs to efficiently retrieve and process real-time data for enhanced performance and reliability.",
  },
  {
    id: 2,
    title: "Enhancing Existing Code",
    text:
      "The client was dissatisfied with the quality of the initial development team. LaSoft had to optimize and refine the existing codebase while introducing new features.",
  },
  {
    id: 3,
    title: "Advanced Analytics",
    text:
      "Building an option to track agency behavior, marketing effectiveness, and client activity.",
  },
  {
    id: 4,
    title: "Marketplace Development",
    text:
      "We optimized and updated a dynamic marketplace for connecting users with premium agency services at affordable rates.",
  },
  {
    id: 5,
    title: "Optimization and update",
    text:
      "Our team ensured that the software solution works seamlessly and offers additional features that increase customer interaction and offer different options without compromising the speed and reliability of the marketplace and applications.",
  },
]

export const SCOPE = [
  {
    id: 1,
    title: <span>Updated and Optimised Solution</span>,
    text: (
      <p>
        We helped to transform the work delivered by the former development
        team. The Lasoft and Zala teams took on the challenge of enhancing the
        platform’s existing features and implementing new functionalities.
      </p>
    ),
  },
  {
    id: 2,
    title: <span>Client-Centric Solutions</span>,
    text: (
      <p>
        The platform is designed to address the core needs of small and
        medium-sized businesses that operate online or handle sensitive customer
        data. Cyber provider helps clients who aim to strengthen their security
        measures increase employee awareness of cybersecurity risks against
        cyberattacks and other threats.
      </p>
    ),
  },
  {
    id: 3,
    title: <span>Cyber Monitoring Report</span>,
    text: (
      <p>
        LaSoft updated a comprehensive cyber monitoring solution for platform.
        This feature provides detailed reports on a company’s website security
        status and actionable recommendations to improve cybersecurity measures
        based on the client’s app scan.
      </p>
    ),
  },
  {
    id: 4,
    title: <span>Cybersecurity Training Course</span>,
    text: (
      <p>
        We enhanced a specialized training module designed for employees. This
        feature educates staff on best cybersecurity practices and tracks their
        learning progress and performance.
      </p>
    ),
  },
  {
    id: 5,
    title: "Marketplace",
    text: (
      <p>
        Professional developers updated and enhanced a dynamic insurance
        marketplace where users can explore and select the best security offers
        tailored to their needs.
      </p>
    ),
  },
]

export const FEATURES = [
  {
    id: 1,
    title: "Admin Portal",
    text:
      "A robust user management system for administrators to easily add, remove, and manage user access and privileges.",
    images: [
      { name: "admin-portal", width: 470, height: 407, alt: "Admin Portal" },
    ],
  },
  {
    id: 2,
    title: "Questionnaire",
    text:
      "A dynamic tool to assess users’ online safety maturity and provide personalized recommendations and training suggestions.",
    images: [
      { name: "questionnaire", width: 470, height: 495, alt: "Questionnaire" },
    ],
  },
  {
    id: 3,
    title: "Marketplace",
    text:
      "A robust user management system for administrators to easily add, remove, and manage user access and privileges.",
    images: [
      { name: "marketplace-1", width: 352, height: 400, alt: "Marketplace" },
      { name: "marketplace-2", width: 306, height: 358, alt: "Marketplace" },
    ],
  },
  {
    id: 4,
    title: "Agent Portal",
    text:
      "Usable app for Agents (insurance brokers) to streamline their workflow. The app allows to administrate users and provides educational materials for brokers and end users. The app is being managed by the admin panel of the core product, ensuring centralized management of both applications.",
    images: [
      { name: "agent-portal", width: 464, height: 552, alt: "Agent Portal" },
    ],
  },
  {
    id: 5,
    title: "Agency Analytics",
    text:
      "Advanced analytics to monitor agency behavior, marketing results, and client acquisition effectiveness.",
    images: [
      {
        name: "agency-analytics",
        width: 970,
        height: 441,
        alt: "Agency Analytics",
      },
    ],
  },
  {
    id: 6,
    title: "Sales Content",
    text:
      "A brokers’ portal offers a page with pre-designed informational and educational materials, including presentations, videos, and brochures to promote the startup’s cyber services effectively.",
    images: [
      { name: "sales-content", width: 444, height: 597, alt: "Sales Content" },
    ],
  },
  {
    id: 7,
    title: "Channels Optimization",
    text:
      "Automated functionality to generate personalized offers for clients based on user behavior, enhancing efficiency for managers.",
    images: [
      {
        name: "channels-optimization",
        width: 444,
        height: 517,
        alt: "Channels Optimization",
      },
    ],
  },
]

export const PROJECTS_TEAM = [
  "2 Fullstack Developers",
  "Business Analyst",
  "Project Manager",
  "QA Specialist",
]
